
function sliderVideo(){
	if (!$('.app-slider--video').length) {
		return false;
	}
	let boxSlider = $('.app-slider--video');
	let Slider = $('.app-slider__body', boxSlider);
	Slider.each(function(index, el) {
		$(this).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: $(this).closest(boxSlider).find('.js-slide--prev'),
			nextArrow: $(this).closest(boxSlider).find('.js-slide--next'),
			focusOnSelect: true,
			dots: true,
			infinite: true,
			fade: true,
		});
	});
}

/**
 * Инит модуля
 */
function init() {
	sliderVideo();
}

module.exports = {
	init,
};
