
function sliderProduct(){
	if (!$('.app-slider--tovar').length) {
		return false;
	}
	let boxSlider = $('.app-slider--tovar');
	let boxSliderNav = $('.app-slider--tovar-nav');
	let Slider = $('.app-slider__body', boxSlider);
	let SliderNav = $('.app-slider__body', boxSliderNav);
	let count = ($('.app-slide', boxSlider)).length;
		Slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			dots: true,
			infinite: true,
			focusOnSelect: true,
			asNavFor: SliderNav,
			prevArrow: boxSlider.find('.js-slide--prev'),
			nextArrow: boxSlider.find('.js-slide--next'),
		});
		SliderNav.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			focusOnSelect: true,
			asNavFor: Slider,
			prevArrow: boxSliderNav.find('.js-slide--prev'),
			nextArrow: boxSliderNav.find('.js-slide--next'),
		});
	if (count > 1) {
		SliderNav.css({
			'height' : '70px',
			'overflow' : 'visible',
		});		
	} else {
		SliderNav.css({
			'height' : 0,
			'overflow' : 'hidden',
		});
	}
	
}

/**
 * Инит модуля
 */
function init() {
	sliderProduct();
}

module.exports = {
	init,
};
